import React, { createContext, useContext, useState } from 'react';

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const [searchValues, setSearchValues] = useState("");

    return (
        <SearchContext.Provider value={{ setSearchValues, searchValues}}>
            {children}
        </SearchContext.Provider>
    );
};

export const useSearchbar = () => {
    return useContext(SearchContext);
};
