import React, { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [sidebarData, setSidebarData] = useState([]);
    const [apiCall, setApiCall] = useState(false);

    return (
        <SidebarContext.Provider value={{ setSidebarData, sidebarData, setApiCall, apiCall }}>
            {children}
        </SidebarContext.Provider>
    );
};

export const useSidebar = () => {
    return useContext(SidebarContext);
};
