import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import { appRoutes, } from "./services/Routes";
import ReactGA from 'react-ga4';

// import { GetCssLinkComponent } from './components/GetCssLink';
import "./assets/fonts/theme-icon/style.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';
// import "./assets/css/style.css";
// import "./assets/css/mobile.css";

import ToastComponent, { showToast } from "./components/ToastComponent";
import ScrollToTop from "./components/ScrollToTop";
import { SidebarProvider } from "./pages/SidebarContext";
import { SearchProvider } from './pages/SearchContext';

// function GetCssLinkComponent () {

//   const screenWidth = window.innerWidth;
//   const screenHeight = window.innerHeight;
//   let css = '';
//   // console.log(window.innerWidth);
//   // console.log(screenHeight);

//   if(screenWidth >= 768 ){
//       import("./assets/css/style.css");  //Desktop
//   } else if(screenWidth <= 767 ){
//       import("./assets/css/mobile.css"); //Mobile
//   } else {}

//   // if(screenWidth >= 375 && screenHeight <= 812 ){
//   //     import("./assets/css/mobile.css");  //iPhone 11
//   // }else if(screenWidth >= 428 && screenHeight <= 926){
//   //     import("./assets/css/mobile.css"); //iphone 12 pro MAx
//   // }else if(screenWidth >= 360 && screenHeight <= 760){
//   //     import("./assets/css/mobile.css"); //sumsung galaxy 21 pro MAx
//   // }else if(screenWidth >= 412 && screenHeight <= 869){
//   //     import("./assets/css/mobile.css"); //Samsung Galaxy S21+
//   // } else{
//   //     import("./assets/css/mobile.css");
//   // }

// };

// GetCssLinkComponent();

function GetCssLinkComponent() {

  const applyCss = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    if (screenWidth >= 768) {
      import("./assets/css/style.css").then(() => {
        
      }).catch(error => {
        // console.error("Error loading desktop CSS:", error);
      });
    } else if (screenWidth <= 767) {
      import("./assets/css/mobile.css").then(() => {
                
      }).catch(error => {
        // console.error("Error loading mobile CSS:", error);
      });
    }
  };

  // Call initially
  applyCss();

  // Listen for window resize event
  window.addEventListener('resize', applyCss);
}

GetCssLinkComponent();

const AppLayout = lazy(() => import("./components/layouts/AppLayout"));
//const NoHeaderLayout = lazy(() => import("./components/layouts/NoHeaderLayout"));

function App() {
  let TRACKING_ID;

  if (process.env.REACT_APP_MODE === "production") {
    TRACKING_ID = "G-Z5QZDPJ47G";
  } else {
    TRACKING_ID = "G-5L6J4EH697";
  }
  // ReactGA.initialize(TRACKING_ID);
  // , { debug: true }

  ReactGA.initialize(TRACKING_ID, {
    gtagUrl: "https://www.googletagmanager.com/gtag/js",
  });

  return (
    <>
      <SidebarProvider>
        <SearchProvider>
        <Router>
          <ScrollToTop />
          <Suspense fallback={null}>
            <Routes>
              {appRoutes.map((route, idx) => {
                return route.element ? (
                  <Route
                    key={idx}
                    path={route.path}
                    element={<route.element />}
                    render={(props) => <AppLayout {...props} />}
                  />
                ) : null;
              })}

              {/* {noUserRoutes.map((route, idx) => {
              return route.element ? (
                <Route
                  key={idx}
                  path={route.path}
                  element={<route.element />}
                  render={(props) => <NoHeaderLayout {...props} />}
                />
              ) : null;
            })}              */}
            </Routes>
          </Suspense>
        </Router>
        </SearchProvider>
      </SidebarProvider>
      <ToastComponent />
    </>
  );
}

export default App;
